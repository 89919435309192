const COLUMN_FILTERS = 'COLUMN_FILTERS'
const GLOBAL_FILTER = 'GLOBAL_FILTER'
const OPERATOR_AND = 'AND'
const OPERATOR_OR = 'OR'

const FILTER_MODE_OPTIONS = [COLUMN_FILTERS, GLOBAL_FILTER]
const OPERATOR_OPTIONS = [OPERATOR_AND, OPERATOR_OR]

export default {
  COLUMN_FILTERS,
  GLOBAL_FILTER,
  FILTER_MODE_OPTIONS,
  OPERATOR_AND,
  OPERATOR_OR,
  OPERATOR_OPTIONS
}
